import { render, staticRenderFns } from "./orderTable.vue?vue&type=template&id=44ab6e98&scoped=true&"
import script from "./orderTable.vue?vue&type=script&lang=js&"
export * from "./orderTable.vue?vue&type=script&lang=js&"
import style0 from "./orderTable.vue?vue&type=style&index=0&id=44ab6e98&prod&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "44ab6e98",
  null
  
)

export default component.exports